import {Category, Item, makeItem, Profile, allProfiles, blankProfile} from './Equipment'


export const initialEquipmentModel: EquipmentModel = {
  profile: blankProfile,
}

export interface EquipmentModel {
  profile: Profile
}

export type EquipmentAction
  = { kind: 'select', profileIndex: number }
  | { kind: 'new' }
  | { kind: 'delete', categoryId: string, itemId: string }
  | { kind: 'clear' }
  // update items fields
  | { kind: 'name', value: string, categoryId: string, itemId: string }
  | { kind: 'nameplatePower', value: number, categoryId: string, itemId: string }
  | { kind: 'averagePower', value: number, categoryId: string, itemId: string }
  | { kind: 'quantity', value: number, categoryId: string, itemId: string }
  | { kind: 'on', categoryId: string, itemId: string }
  | { kind: 'hour', index: number, value: number, categoryId: string, itemId: string }


// TODO: refactor to use immutable.js to simplify deep updates
export const equipmentReducer = (state: EquipmentModel, action: EquipmentAction): EquipmentModel => {
  switch (action.kind) {
    case 'select':
      return {
        ...state,
        profile: allProfiles[action.profileIndex],
      }
    case 'new': {
      const profile = state.profile
      const categoryIndex = profile.categories.length - 1
      const category = profile.categories[categoryIndex]
      const newCategory = {...category, items: category.items.concat([makeItem()])}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'delete': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const newCategory = {...category, items: category.items.filter(x => x.id !== action.itemId)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'clear': {
      const profile = state.profile
      const newCategories = profile.categories.map(category => {
        return {
          ...category,
          items: category.items.map(item => { return {...item, quantity: 0}}),
        }
      })
      const newProfile = {...profile, categories: newCategories}
      return {
        ...state,
        profile: newProfile,
      }
    }

    case 'name': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const itemIndex = category.items.findIndex(x => x.id === action.itemId)
      const item = category.items[itemIndex]
      const newItem = {...item, name: action.value}
      const newCategory = {...category, items: replace(category.items, itemIndex, newItem)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'nameplatePower': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const itemIndex = category.items.findIndex(x => x.id === action.itemId)
      const item = category.items[itemIndex]

      const nameplatePower = action.value
      // for not custom items update average power using duty cycle
      const averagePower = item.custom ? item.averagePower : nameplatePower * item.d
      const newItem = {...item, nameplatePower, averagePower}
      const newCategory = {...category, items: replace(category.items, itemIndex, newItem)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'averagePower': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const itemIndex = category.items.findIndex(x => x.id === action.itemId)
      const item = category.items[itemIndex]

      // do not update average powr for not custom items
      if (!item.custom) { return state }
      const averagePower = action.value
      const newItem = {...item, averagePower}
      const newCategory = {...category, items: replace(category.items, itemIndex, newItem)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'quantity': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const itemIndex = category.items.findIndex(x => x.id === action.itemId)
      const item = category.items[itemIndex]
      const newItem = {...item, quantity: action.value}
      const newCategory = {...category, items: replace(category.items, itemIndex, newItem)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'on': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const itemIndex = category.items.findIndex(x => x.id === action.itemId)
      const item = category.items[itemIndex]
      const newItem = {...item, hours: [11, 4, 9]}
      const newCategory = {...category, items: replace(category.items, itemIndex, newItem)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'hour': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const category = profile.categories[categoryIndex]
      const itemIndex = category.items.findIndex(x => x.id === action.itemId)
      const item = category.items[itemIndex]
      const newHours = [...item.hours]
      newHours[action.index] = action.value
      const newItem = {...item, hours: newHours}
      const newCategory = {...category, items: replace(category.items, itemIndex, newItem)}
      const newProfile = {...profile, categories: replace(profile.categories, categoryIndex, newCategory)}
      return {
        ...state,
        profile: newProfile,
      }
    }

    default:
      return state
  }
}


const replace = <T>(items: T[], index: number, item: T): T[] => {
  const pre = items.slice(0, index)
  const suf = items.slice(index + 1)
  return pre.concat([item], suf)
}


export const getItemEnergy = (x: Item): number => x.averagePower * x.quantity * (x.hours[0] + x.hours[1] + x.hours[2]) / 1000

export const getCategoryEnergy = (x: Category): number => x.items
  .map(x => getItemEnergy(x))
  .reduce((acc, x) => acc + x, 0)

export const getCategoriesEnergy = (xs: Category[]): number => xs
  .map(x => getCategoryEnergy(x))
  .reduce((acc, x) => acc + x, 0)
