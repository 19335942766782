import Profiles from './profiles.json'
import {EquipmentItem} from './Model'
import {v4 as uuidv4} from 'uuid'


export interface Profile {
  id: string,
  name: string,
  description: string,
  categories: Category[],
}

export interface Category {
  id: string,
  name: string,
  items: Item[],
  custom?: boolean,
}

export interface Item {
  id: string,
  name: string,
  nameplatePower: number,
  averagePower: number,
  d: number,
  quantity: number,
  hours: number[], // [day,evening,night]
  custom?: boolean,
}


export const blankProfile = Profiles[0]
export const allProfiles: Profile[] = Profiles.slice(1)


export const getEquipmentList = (profile: Profile): EquipmentItem[] => {
  return profile.categories.flatMap(x => x.items).map(x => {
    return {
      name: x.name,
      power: x.averagePower,
      quantity: x.quantity,
      hours: x.hours,
    }
  })
}


export const makeItem = (): Item => {
  return {
    id: uuidv4(),
    name: 'Custom Equipment',
    nameplatePower: 0,
    averagePower: 0,
    d: 1.0,
    quantity: 1,
    hours: [0, 0, 0],
    custom: true,
  }
}


// TODO: find item and category index by ID
