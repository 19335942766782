import React, {ReactNode, useState} from 'react'
import {GoChevronDown, GoChevronRight} from 'react-icons/go'


type InfoTipProps = { children: ReactNode }

const InfoTip = ({children}: InfoTipProps) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <a className="moreInfoLink" href="/#" onClick={_ => setShow(!show)}>[more info]</a>
      {show &&
      <div className="moreInfoText">{children}</div>}
    </>)
}


type StepTipProps = {
  title: string
  description: string
  children: ReactNode
}

const StepTip = ({children, description, title}: StepTipProps) => {
  return (
    <li>
      <b>{title}</b> - {description}
      <InfoTip>
        {children}
      </InfoTip>
    </li>
  )
}


type CollapsibleSectionProps = {
  title: string,
  children: ReactNode,
  expanded: boolean,
}

const CollapsibleSection = ({title, children, expanded}: CollapsibleSectionProps) => {
  const [show, setShow] = useState(expanded)
  const toggle = () => { setShow(!show) }
  const iconStyle = {marginBottom: '3px'}

  return (
    <>
      <h5 onClick={toggle} style={{cursor: 'pointer'}}>
        <span>
          {show ?
            <GoChevronDown style={iconStyle}/> :
            <GoChevronRight style={iconStyle}/>}
        </span>
        <span>{title}</span>
      </h5>
      {show && children}
    </>
  )
}


const Intro = () => {
  return (
    <div className='py-3'>
      <h2>HOMER Powering Health Tool</h2>

      <CollapsibleSection title='What is this tool for?' expanded={true}>
        <p>
          The HOMER Powering Health Tool is a free online model to create initial designs of electric power systems for health care facilities that have no other power supply or have grid electricity available for a predictable period of hours each day. The tool is intended for project managers, engineers and financiers in the energy industry to simplify the design process for such systems. The tool models optimal combinations of power supply options to meet electrical loads of a health facility at least cost based on the given inputs. It compares combinations of grid electricity (if available), batteries (lithium-ion or lead-acid), solar photovoltaics (PV), and generator sets fueled by diesel, gasoline or propane. The model runs entirely online and can be used an unlimited number of times with no need to sign in or download software.
        </p>
      </CollapsibleSection>

      <CollapsibleSection title='How to use the tool?' expanded={false}>
        <p>
          Read the <strong>guidance note</strong> (<a href='/docs/HOMER%20Powering%20Health%20Tool%20User%20Guide%202020-05-29.docx' target='_blank'>Word</a>, <a href='/docs/HOMER%20Powering%20Health%20Tool%20User%20Guide%202020-05-29.pdf' target='_blank'>PDF</a>) for important information on using the tool, including in context of health care facilities responding to COVID-19. Follow the steps below to input data on local energy costs and health care facility electrical loads.
        </p>
        <ol>
          <StepTip
            title="Location and Time Zone"
            description="Use the map to indicate your approximate location.">
            <p>
              The amount of energy available from the sun is directly related to location. HOMER obtains monthly average solar resource values from a variety of databases at NREL and NASA to synthesize a year of hourly data. These are calculated based on the latitude and longitude of a site and satellite measurements of annual cloud cover.
            </p>
          </StepTip>

          <StepTip
            title="Power Assumptions"
            description="The values that are shown are simply default values. The user is encouraged to enter local values for cost and availability of the electric grid and on-site power generation equipment.">
            <p>
              In areas where the electricity grid is available only part of the day, the scheduling of the grid can have a significant impact on the design of a suitable power supply system. The availability and cost of supply and installation of PV panels, batteries and diesel fuel will vary by location. The default cost values entered here are roughly accurate for much of Africa but should not be relied upon without verification. Grid electricity costs may be estimated from a recent bill (e.g. total cost divided by kilowatt-hours consumed). When calculating the cost of PV, include the cost of all related components (the cost of the panels, installation, mounting frames and other hardware), but not the inverter or batteries, which are sized separately by HOMER. Retail pump prices by country for petrol/gasoline and diesel can be found at <a href='https://data.worldbank.org/indicator'>https://data.worldbank.org/indicator</a> or <a href='https://www.globalpetrolprices.com'>https://www.globalpetrolprices.com</a>.
            </p>
          </StepTip>

          <StepTip
            title="Financial Assumptions"
            description="Enter the Real Interest rate.">
            <p>
              HOMER minimizes the discounted cash flow of the project. The interest rate determines the tradeoff between the initial capital cost and future operating costs. It has a significant effect on the optimal system design. Lower interest rates will favor PV over diesel generators. The real interest rate can be approximated as the difference between a bank's interest rate on loans and the estimated rate of inflation.
            </p>
          </StepTip>

          <StepTip
            title="Electric Load Inputs"
            description="Enter the type and number of electrical devices used in the health facility and the time of day they are used. Select one of four health facility tiers as a starting point and the quantity of devices and hours of use can be then adjusted or entered manually. ">
            <p>
              The table is populated with common equipment types found in rural health facilities including typical power ratings. The time of day that power is needed and the peak power demand have an impact on the sizing of equipment and the optimal configuration. For the most accurate simulation, this step may require a walk-through of an existing clinic. <strong>Note: The tool is not intended to design the health facilities themselves.</strong> In all cases, the equipment quantity, power, and hours of use are inputs that should be reviewed and adjusted as needed to meet the needs of a given facility, based on an energy audit for the specific site where possible. If there is no health facility yet, the information provided in the User Guide can inform an estimate of the potential electrical load.
            </p>
          </StepTip>

          <StepTip
            title="Click the ‘Run HOMER®’ button at the bottom of the webpage."
            description="">
            <p>
              Once you have defined all the necessary inputs HOMER will analyze every possible combination of power supply options that meet the demand.
            </p>
          </StepTip>
        </ol>

        <p>
          <strong>Note: The results will appear as a new page if your browser allows pop-ups or you will have the opportunity to open the results in a new page by clicking on a link after the calculation is complete.</strong>
        </p>
        <ul>
          <li>
            The new page will provide a table of optimized configurations ranked low to high based on the cost per unit of electricity over the project lifetime. Results will include configurations for PV-battery, PV-battery-backup generator, battery-generator, and generator only (for comparison).
          </li>
          <li>
            From the new page, you can select a configuration for detailed results based on the given inputs. The HOMER Pro output file for the simulation can also be downloaded for users with HOMER Pro desktop application.
          </li>
          <li>
            To run a new simulation, you can either load the tool again and start from the default parameters, or you may exit out of the pop-up linking the simulation results and you may start from your recently calculated inputs.
          </li>
        </ul>
      </CollapsibleSection>
    </div>
  )
}

export default Intro
