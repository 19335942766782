import './Table.css'
import React, {useContext, useState} from 'react'
import {Category, Item} from './Equipment'
import {GoChevronDown, GoChevronRight, GoPlus, GoX} from 'react-icons/go'
import {EquipmentContext} from './EquipmentContext'
import NumberInput from './NumberInput'
import BootstrapSwitchButton from 'bootstrap-switch-button-react/lib/bootstrap-switch-button-react'
import {getCategoriesEnergy, getCategoryEnergy, getItemEnergy} from './EquipmentModel'


export interface TableProp {
  categories: Category[]
}


const Table = ({categories}: TableProp) => {

  return (<table className='table table-sm table-borderless table-hover'>
    <thead>
      <TableHeader/>
    </thead>
    <tbody>
      {categories.map(category => { return (<TableGroup category={category} key={category.id}/>)})}
      <TotalRow categories={categories}/>
    </tbody>
  </table>)
}


type TableGroupProps = { category: Category }

const TableGroup = ({category}: TableGroupProps) => {
  const context = useContext(EquipmentContext)
  const [expanded, setExpanded] = useState(true)
  const toggle = () => setExpanded(!expanded)

  const handleAdd = () => { context.dispatch({kind: 'new'})}

  const total = getCategoryEnergy(category)

  return (<>
    <tr onClick={toggle} className='table-secondary' style={{cursor: 'pointer'}}>
      <td colSpan={8}>
        {expanded ? <GoChevronDown className='mr-1'/> : <GoChevronRight className='mr-1'/>}
        <strong>{category.name}</strong>
      </td>
      <td className='text-center align-middle'><strong>{format1.format(total)}</strong></td>
    </tr>
    {expanded && <>
      {category.items.map(item => <TableRow category={category} item={item} key={item.id}/>)}
      {category.custom &&
      <tr>
        <td colSpan={9}>
          <button className='btn btn-sm btn-outline-primary' onClick={handleAdd}>
            <GoPlus/><span className='align-middle'> Add</span>
          </button>
        </td>
      </tr>}
    </>}
  </>)
}

const format0 = new Intl.NumberFormat('en', {maximumFractionDigits: 0})
const format1 = new Intl.NumberFormat('en', {maximumFractionDigits: 1, minimumFractionDigits: 1})

type TableRowProps = { category: Category, item: Item }

const TableRow = ({category, item}: TableRowProps) => {
  const {dispatch} = useContext(EquipmentContext)
  const totalEnergy = getItemEnergy(item)

  const totalHours = item.hours[0] + item.hours[1] + item.hours[2]
  const isAlwaysOn = totalHours >= 24

  const onSetName = (value: string) => { dispatch({kind: 'name', value, categoryId: category.id, itemId: item.id}) }
  const onSetQuantity = (value: number) => { dispatch({kind: 'quantity', value, categoryId: category.id, itemId: item.id}) }
  const onSetNameplatePower = (value: number) => { dispatch({kind: 'nameplatePower', value, categoryId: category.id, itemId: item.id}) }
  const onSetAveragePower = (value: number) => { dispatch({kind: 'averagePower', value, categoryId: category.id, itemId: item.id}) }
  const onSetHour = (index: number, value: number) => { dispatch({kind: 'hour', index, value, categoryId: category.id, itemId: item.id}) }
  const onAlways = () => { dispatch({kind: 'on', categoryId: category.id, itemId: item.id}) }
  const onDelete = () => { dispatch({kind: 'delete', categoryId: category.id, itemId: item.id}) }

  return (<tr>
    <td className='align-middle'>
      {item.custom ?
        <div className='d-flex flex-row'>
          <input className='form-control form-control-sm' defaultValue={item.name} onChange={ev => onSetName(ev.target.value)}/>
          <button className='btn btn-sm btn-outline-danger ml-2' onClick={onDelete}>
            <GoX style={{marginBottom: '3px'}}/>
          </button>
        </div> :
        item.name}
    </td>
    <td>
      <NumberInput id='quantity' value={item.quantity} onChange={onSetQuantity}/>
    </td>
    <td>
      <NumberInput id='nameplatePower' value={item.nameplatePower} onChange={onSetNameplatePower}/>
    </td>
    <td className='text-center align-middle'>
      {item.custom ?
        <div>
          <NumberInput id='averagePower' value={item.averagePower} onChange={onSetAveragePower} max={item.nameplatePower}/>
        </div> :
        format0.format(item.averagePower)}</td>
    <td className='text-center align-middle'>
      <BootstrapSwitchButton onstyle='success' onlabel='Yes' offlabel='No' size='sm'
                             checked={isAlwaysOn} onChange={onAlways}/>
    </td>
    <td>
      <NumberInput id='h0' value={item.hours[0]} onChange={x => onSetHour(0, x)} max={11}/>
    </td>
    <td>
      <NumberInput id='h1' value={item.hours[1]} onChange={x => onSetHour(1, x)} max={4}/>
    </td>
    <td>
      <NumberInput id='h2' value={item.hours[2]} onChange={x => onSetHour(2, x)} max={9}/>
    </td>
    <td className='text-center align-middle'>{format1.format(totalEnergy)}</td>
  </tr>)
}

interface TotalRowProps {
  categories: Category[]
}

const TotalRow = ({categories}: TotalRowProps) => {
  const total = getCategoriesEnergy(categories)
  return (<tr className='table-secondary'>
    <td colSpan={8}>
      <strong>Total</strong>
    </td>
    <td className='text-center align-middle'><strong>{format1.format(total)}</strong></td>
  </tr>)
}

const TableHeader = () => {
  return (<tr>
    <th>Equipment</th>
    <th style={{width: '100px'}}>Quantity</th>
    <th style={{width: '100px'}}>Nameplate Power<br/>(W)</th>
    <th style={{width: '100px'}}>Average Power<br/>(W)</th>
    <th style={{width: '75px', zIndex: 1025}}>Always on?</th>
    <th style={{width: '75px'}}>Daytime<br/>Hours on<br/>7:00-<br/>17:59</th>
    <th style={{width: '75px'}}>Evening<br/>Hours on<br/>18:00-<br/>21:59</th>
    <th style={{width: '75px'}}>Night<br/>Hours on<br/>22:00-<br/>6:59</th>
    <th style={{width: '100px'}}>Total Energy<br/>(kW·h/day)</th>
  </tr>)
}

export default Table
