import React, {useContext, useEffect, useState} from 'react'
import {EquipmentEditor} from './EquipmentEditor'
import Modal from 'react-bootstrap/Modal'
import InputModel from './Model'
import {calculate, delay, getStatus} from './Service'
import {EquipmentContext} from './EquipmentContext'
import {getEquipmentList, Profile} from './Equipment'
import {GoAlert} from 'react-icons/all'
import InfoTip from './InfoTip'
import LocationPicker from './LocationPicker'
import {getCategoriesEnergy} from './EquipmentModel'


export type StepProps<T> = {
  value: T;
  onChange: (value: T, isValid?: boolean) => void;
}


type FieldProps = {
  label: string
  id: string
  placeholder?: string
  unit?: string
  tip?: string
  value: number
  disabled?: boolean
  onChange: (value: number) => void
}


const Field = ({label, id, placeholder, unit, tip, value, disabled, onChange}: FieldProps) => {
  const [state, setState] = useState(value.toString())
  const [error, setError] = useState(false)

  useEffect(() => {
    setState(value.toString())
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setState(newState)
    setError(false)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setState(newState)
    const n = Number(newState)
    if (!isNaN(n)) {
      onChange(n)
    } else {
      setError(true)
    }
  }

  const s = `form-control field-small ${error ? 'is-invalid' : ''}`

  return (
    <div className='form-group row'>
      <div className='col-3 text-right'>
        <label className='col-form-label'>{label}</label>
      </div>
      <div className='col-3'>
        <div className='input-group'>
          <input className={s}
                 name={id}
                 placeholder={placeholder}
                 disabled={disabled}
                 value={state}
                 onChange={(e) => handleChange(e)}
                 onBlur={(e) => handleBlur(e)}/>
          {unit &&
          <div className="input-group-append">
            <div className="input-group-text">{unit}</div>
          </div>}
        </div>
      </div>
      {tip &&
      <div className='d-flex flex-column justify-content-center'>
        <InfoTip id={`${id}-tip`} text={tip}/>
      </div>}
    </div>
  )
}


interface ErrorNoteProps {
  message: string,
}

const ErrorNote = ({message}: ErrorNoteProps) => {
  return (
    <p key={message} className='text-danger mb-1'>
      <GoAlert className='m-1'/>
      <span className='align-middle'>{message}</span>
    </p>
  )
}


// all
const fuelOptions = ['Diesel', 'Gasoline', 'Propane']
const storageOptions = ['Li-Ion Battery', 'Lead Acid Battery']


const defaultModel = {
  location: {
    coords: {lat: 9.0324919, lng: 6.4341224},
    address: 'Nigeria',
  },
  interestRate: 5,

  gridStart: 0,
  gridDuration: 0,
  gridCost: 0.15,
  fuelCost: 1, // Diesel: 1, Gasoline: ?, Propane: ?
  pvCost: 1147,
  storageCost: 432, // Li-Ion Battery: 432, Lead Acid Battery: 270
  converterCost: 608,
  generatorCost: 378,

  fuelName: fuelOptions[0],
  storageName: storageOptions[0],
}


type Model = typeof defaultModel

const getInputFromModel = (model: Model, profile: Profile): InputModel => {
  return {
    latitude: model.location.coords.lat,
    longitude: model.location.coords.lng,
    address: model.location.address,
    interestRate: model.interestRate,

    gridStart: model.gridStart,
    gridDuration: model.gridDuration,
    gridCost: model.gridCost,
    fuelCost: model.fuelCost,
    pvCost: model.pvCost,
    storageCost: model.storageCost,
    converterCost: model.converterCost,
    generatorCost: model.generatorCost,

    fuelName: model.fuelName,
    storageName: model.storageName,

    equipment: getEquipmentList(profile),
  }
}

const validateInput = (model: Model, profile: Profile): string[] => {
  const items = profile.categories.flatMap(x => x.items)
  const errors = []
  const eps = 0.001

  // number of hours
  const h1 = items.map(x => x.hours[0]).some(x => x > 11)
  const h2 = items.map(x => x.hours[1]).some(x => x > 4)
  const h3 = items.map(x => x.hours[2]).some(x => x > 9)
  if (h1 || h2 || h3) { errors.push('Incorrect number of hours for some equipment items.')}

  // total load > 0
  const total = getCategoriesEnergy(profile.categories)
  if (total < eps) { errors.push('Total load is 0.') }

  // average power <= nameplate power
  const p = items.some(x => x.averagePower > x.nameplatePower + eps)
  if (p) { errors.push('Average power is greater than nameplate power for some equipment items.')}

  return errors
}


const hours = Array.from({length: 24}, (_, i) => i)

const Wizard = () => {
  const [model, setModel] = useState(defaultModel)

  const equipmentContext = useContext(EquipmentContext)

  const [progress, setProgress] = useState(0)
  const [id, setId] = useState<string | null>(null)
  const [wait, setWait] = useState(false)
  const [ready, setReady] = useState(false)

  const [errors, setErrors] = useState<string[]>([])

  const onChange = (value: Partial<Model>) => {
    const s = {...model, ...value}
    setModel(s)
  }

  const onStart = async () => {
    // validate model
    const validation = validateInput(model, equipmentContext.state.profile)
    const isValid = validation.length === 0
    setErrors(validation)
    if (!isValid) { return }

    // reset status
    setReady(false)
    setProgress(0)

    // calculate model
    setWait(true)
    const input = getInputFromModel(model, equipmentContext.state.profile)
    const rv = await calculate(input)
    setId(rv.id)
  }

  useEffect(() => {
    if (id === null) { return }

    let cancel = false

    const run = async () => {
      // check status
      let it = 0
      let p = 0
      while (it < 100) {
        if (cancel) { return }
        await delay(5000)
        const rv = await getStatus(id)
        p = Math.max(p, rv.progress)
        setProgress(p)
        if (rv.status === 'Completed' || p >= 100) { break }
        it++
      }

      // redirect if ready
      setReady(true)
      window.open(`result/${id}`, '_blank')
    }

    run()

    return () => {
      cancel = true
    }
  }, [id])

  // reset errors when profile changed
  useEffect(
    () => { setErrors([]) },
    [equipmentContext.state.profile.id],
  )

  const handleHide = () => {
    if (ready) { setWait(false) }
  }

  const {coords: {lat, lng}} = model.location
  const locationLabel = lat.toFixed(3) + ', ' + lng.toFixed(3)

  return (<>
      <div className='py-3' onSubmit={ev => ev.preventDefault()}>

        <fieldset>
          <legend>1) Location</legend>

          <LocationPicker value={model.location} onChange={x => onChange({location: x})}/>

          <div className='form-group row'>
            <div className='col-3 text-right'>
              <label className='col-form-label'>Address</label>
            </div>
            <div className='col-9'>
              <input type='text' readOnly className='form-control-plaintext' id='location' value={model.location.address}/>
            </div>
            <div className='col-3 text-right'>
              <label className='col-form-label'>Location</label>
            </div>
            <div className='col-9'>
              <input type='text' readOnly className='form-control-plaintext' id='location' value={locationLabel}/>
            </div>
          </div>

        </fieldset>

        <fieldset>
          <legend>2) Power Assumptions</legend>

          <h5>Electric Grid (if available)</h5>
          <div className='form-group row'>
            <div className='col-3 text-right'>
              <label className='col-form-label'>Grid on at (time)</label>
            </div>
            <div className='col-3'>
              <select className='form-control'
                      defaultValue={model.gridStart}
                      onChange={ev => onChange({gridStart: Number(ev.target.value)})}>
                {hours.map(x => <option key={x} value={x}>{`${x}:00`}</option>)}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-3 text-right'>
              <label className='col-form-label'>for how many hours?</label>
            </div>
            <div className='col-3'>
              <select className='form-control'
                      defaultValue={model.gridDuration}
                      onChange={ev => onChange({gridDuration: Number(ev.target.value)})}>
                {hours.map(x => <option key={x} value={x}>{x}</option>)}
              </select>
            </div>
          </div>

          <Field label='Electric Grid Price' unit='$/kW·h' id='gridCost'
                 value={model.gridCost}
                 onChange={x => onChange({gridCost: x})}/>


          <h5>On-site generation</h5>

          <div className='form-group row'>
            <div className='col-3 text-right'>
              <label className='col-form-label'>Type of Fuel</label>
            </div>
            <div className='col-3'>
              <select className='form-control'
                      defaultValue={model.fuelName}
                      onChange={ev => onChange({fuelName: ev.target.value})}>
                {fuelOptions.map(x => <option key={x} value={x}>{x}</option>)}
              </select>
            </div>
          </div>

          <Field label='Cost of Fuel' unit='$/liter' id='fuelCost'
                 value={model.fuelCost}
                 onChange={x => onChange({fuelCost: x})}/>

          <Field label='Cost of PV System' unit='$/kW' id='pvCost' tip='Total installed cost of PV and DC MPPT'
                 value={model.pvCost}
                 onChange={x => onChange({pvCost: x})}/>

          <div className='form-group row'>
            <div className='col-3 text-right'>
              <label className='col-form-label'>Type of Battery</label>
            </div>
            <div className='col-3'>
              <select className='form-control'
                      defaultValue={model.storageName}
                      onChange={ev => onChange({storageName: ev.target.value})}>
                {storageOptions.map(x => <option key={x} value={x}>{x}</option>)}
              </select>
            </div>
          </div>

          <Field label='Cost of Batteries' unit='$/kW·h' id='storageCost'
                 value={model.storageCost}
                 onChange={x => onChange({storageCost: x})}/>

          <Field label='Cost of bi-directional Inverter' unit='$/kW' id='storageCost' tip='Includes monitoring system'
                 value={model.converterCost}
                 onChange={x => onChange({converterCost: x})}/>

          <Field label='Generator Installed Cost' unit='$/kW' id='storageCost'
                 value={model.generatorCost}
                 onChange={x => onChange({generatorCost: x})}/>

        </fieldset>

        <fieldset>
          <legend>3) Financial Assumptions</legend>

          <Field label='Interest Rate' unit='%/year' id='interestRate'
                 value={model.interestRate}
                 onChange={x => onChange({interestRate: x})}/>
        </fieldset>

        <fieldset>
          <legend>4) Electric Load Inputs</legend>

          <EquipmentEditor/>
        </fieldset>

        <fieldset>
          <legend>5) Run HOMER®</legend>

          {errors.map(x => <ErrorNote message={x}/>)}

          <button className='btn btn-success' onClick={onStart}>Calculate</button>
        </fieldset>

      </div>

      <Modal show={wait} centered onHide={handleHide}>
        <Modal.Header closeButton={ready}>
          <Modal.Title>{!ready ? 'Calculating...' : 'Completed'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!ready ?
            <div className='progress'>
              <div className='progress-bar progress-bar-striped progress-bar-animated bg-success'
                   role='progressbar' aria-valuemin={0} aria-valuemax={100} aria-valuenow={progress}
                   style={{width: `${progress}%`}}/>
            </div> :
            <>
              <div>
                <a href={`result/${id}`} target='_blank'>View Results</a>
              </div>
            </>}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Wizard
