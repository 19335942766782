import React, {createContext, useReducer} from 'react'
import {EquipmentAction, EquipmentModel, equipmentReducer, initialEquipmentModel} from './EquipmentModel'


type EquipmentContextValue = {
  state: EquipmentModel,
  dispatch: React.Dispatch<EquipmentAction>,
}

export const EquipmentContext = createContext<EquipmentContextValue>({state: initialEquipmentModel, dispatch: () => null})

export const EquipmentProvider: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(equipmentReducer, initialEquipmentModel)
  return (
    <EquipmentContext.Provider value={{state, dispatch}}>
      {children}
    </EquipmentContext.Provider>
  )
}
