import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import Intro from './Intro'
import Wizard from './Wizard'
import Outro from './Outro'
import {EquipmentProvider} from './EquipmentContext'


const App = () => {
  return (
    <>
      <header className='d-flex justify-content-center'>
        <div id='header' className='d-flex flex-row align-items-center'>
          <a href='https://www.usaid.gov/energy/powering-health' target='_blank'>
            <img src='logo/poweringhealth.png' alt='Powering Health' id='poweringhealth'/></a>
          <a href='https://www.usaid.gov/energy/powering-health' target='_blank'>
            <img src='logo/usaid.png' alt='USAID' id='usaid'/></a>
          <a href='https://www.esmap.org/' target='_blank'>
            <img src='logo/esmap.png' alt='ESMAP' id='esmap'/></a>
          <a href='https://www.homerenergy.com/' target='_blank'>
            <img src='logo/homer.png' alt='HOMER' id='homer'/></a>
          <a href='https://www.wecaresolar.org/' target='_blank'>
            <img src='logo/wecaresolar.png' alt='WeCareSolar' id='wecaresolar'/></a>
        </div>
      </header>

      <main className='container align-self-center flex-grow-1 flex-shrink-0'>
        <Intro/>
        <hr/>
        <EquipmentProvider>
          <Wizard/>
        </EquipmentProvider>
        <hr/>
        <Outro/>
      </main>

      <footer>
        <div className='p-2'><a href="http://www.homerenergy.com/">HOMER Energy, LLC © {new Date().getFullYear()}</a></div>
      </footer>
    </>
  )
}

export default App
