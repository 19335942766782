import React, {useContext} from 'react'
import {allProfiles} from './Equipment'
import Table from './Table'
import {EquipmentContext} from './EquipmentContext'


export const EquipmentEditor = () => {
  const {state, dispatch} = useContext(EquipmentContext)

  const onSelect = (profileIndex: number) => { dispatch({kind: 'select', profileIndex}) }
  const onClear = () => { dispatch({kind: 'clear'}) }

  return (
    <>
      <div>
        {allProfiles.map((x, index) =>
          <button key={index} className='btn btn-outline-primary mr-3 mb-3' onClick={() => onSelect(index)}>
            {x.name}<br/>{x.description}
          </button>)}
        {state.profile &&
        <button className='btn btn-outline-secondary mr-3 mb-3' onClick={onClear}>Clear<br/>Quantities</button>}
      </div>
      {state.profile &&
      <Table categories={state.profile.categories}/>}
    </>
  )
}
