import React, {ReactNode} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {GoInfo} from 'react-icons/go'


const defaultValue = {
  id: '',
  text: '' as ReactNode,
}

type Props = typeof defaultValue


const InfoTip = ({id, text}: Props) => {
  return (
    <OverlayTrigger
      placement='auto'
      overlay={
        <Tooltip id={id}>
          <div className='keep-lines'>{text}</div>
        </Tooltip>
      }>
      <GoInfo className='text-primary' style={{height: '20px'}}/>
    </OverlayTrigger>
  )
}


export default InfoTip
